.groupVLAN {
    position: relative;
    width: 100%;
    height:100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px;
text-align: center;
}

.groupVLAN:after {
    content: '';
    position: absolute;
    width: 1100%; height: 1100%;
    top: 20px; right: -500%;
    background: rgba(255,255,255,.7);
    transform-origin: 54% 0;
    transform: rotate(45deg);
z-index: -1;
}

.box {
    width: 100%;
    height:100%;
    margin: 0 auto;
    position: relative;    
    background: blue;
}
.box:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 0, 0, 0.7);
    clip-path: polygon(0 60px,60px 0,calc(100% - 60px) 0,100% 60px,100% calc(100% - 60px),calc(100% - 60px) 100%,60px 100%,0 calc(100% - 60px),0 60px,6px  calc(60px + 2.49px),6px calc(100% - 60px - 2.49px),calc(60px + 2.49px) calc(100% - 6px),calc(100% - 60px - 2.49px) calc(100% - 6px),calc(100% - 6px) calc(100% - 60px - 2.49px),calc(100% - 6px) calc(60px + 2.49px),calc(100% - 60px - 2.49px) 6px,calc(60px + 2.49px) 6px,6px calc(60px + 2.49px));
}

/* https://css-generators.com/custom-corners/ */
.box2 {
    width: 100%;
    height:100%;
    margin: 0 auto;
    position: relative;    
    border: none;
    background: transparent;
    box-shadow: 0 0 #0000;
}

.box2:hover {
    box-shadow: 0 0 #0000;
}

.box2:before {
    content: "";    
    margin: auto auto;
    position: absolute;
    inset: 0;
    background: rgba(255, 0, 0,0.2);
    clip-path: polygon(0 60px,60px 0,calc(100% - 60px) 0,100% 60px,100% calc(100% - 60px),calc(100% - 60px) 100%,60px 100%,0 calc(100% - 60px));
}

div.groupHandle {
width: 100%;
height: 100%;
background: blue;
position: absolute;
top: 0;
left: 0;
border-radius: 0;
transform: none;
border: none;
opacity: 0;
}