.octagonWrap {
    width:100%;
    height:100%;    
    float: left;
    position: relative;
    overflow: hidden;
    padding:30px;
    z-index: 0;
}
.octagon {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    transform: rotate(45deg);    
    
    z-index: 0;
    
}
.octagon:before {
    position: absolute;
    /* There needs to be a negative value here to cancel
     * out the width of the border. It's currently -3px,
     * but if the border were 5px, then it'd be -5px.
     */
    top: -3px; right: -3px; bottom: -3px; left: -3px;
    transform: rotate(45deg);
    content: '';
    
    z-index: 0;
    border: inherit;
}
