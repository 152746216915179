.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
  }
  
  .dndflow .dndnode {
    height: 10px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  }
  
  .dndflow .dndnode.output {
    border-color: #ff0072;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 250px;
    }
  }
  
/* 
  .customNodeBody {
    width: 80px;
    height: 30px;
    border: 2px solid black;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  .customNode:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    height: 20px;
    width: 40px;
    transform: translate(-50%, 0);
    background: #d6d5e6;
    z-index: 1000;
    line-height: 1;
    border-radius: 4px;
    color: #fff;
    font-size: 9px;
    border: 2px solid #222138;
  }
   */
  div.sourceHandle {
    width: 100%;
    height: 30%;
    position: absolute;
    top: 70%;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
  }
  
  div.targetHandle {
    width: 100%;
    height: 100%;
    background: red;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
  }