.context-menu {
    background: white; /* white; */
    border-style: solid;
    border-color: black;
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
  }
  
  .context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
  }
  
  .context-menu button:hover {
    background: rgb(189, 187, 204);
  }
  